import { Typography } from '@mui/material';
import Layout from '../../layout/layout';

function TermsAndConditions() {
	return (
		<Layout>
			<div className="terms-and-conditions-container page-top-space">
				<div className="container">
					<Typography variant="h1">
						nuclecode Terms And Conditions
					</Typography>

					<section>
						Welcome to nuclecode! These terms and conditions outline 
						the rules and regulations for the use of our website and 
						services, including web development, design, and related offerings.
					</section>

					<section>
						By accessing this website or using our services, you accept 
						these terms and conditions in full. Do not continue to use 
						nuclecode's website or services if you do not accept 
						all of the terms and conditions stated on this page.
					</section>

					<section className="bold">
						1. Definitions
					</section>

					<section>
						<ul>
							<li>
								"nuclecode," "we," "us," or "our" refers to nuclecode Ltd., 
								a web development company registered in the United Kingdom 
								under company number 15061644.
							</li>
							<li>
								"You" refers to the user or client engaging with 
								nuclecode's website or services.
							</li>
						</ul>
					</section>

					<section className="bold">
						2. Services
					</section>

					<section>
						<ul>
							<li>
								nuclecode provides web development, design, and 
								related services to clients worldwide.
							</li>
							<li>
								Our services are delivered according to the 
								specifications outlined in the agreed-upon project proposal or contract.
							</li>
						</ul>
					</section>

					<section className="bold">
						3. Fees and Payments
					</section>

					<section>
						<ul>
							<li>
								Fees for nuclecode's services are determined 
								based on the scope of the project and are 
								outlined in the project proposal or contract.
							</li>
							<li>
								Payment terms are specified in the project 
								proposal or contract. Failure to adhere to 
								payment terms may result in project delays or termination.
							</li>
							<li>
								All fees are non-refundable once services have been rendered.
							</li>
						</ul>
					</section>

					<section className="bold">
						4. Intellectual Property
					</section>

					<section>
						<ul>
							<li>
								nuclecode retains intellectual property rights 
								to all code, designs, and materials developed 
								as part of our services, unless otherwise agreed upon in writing.
							</li>
							<li>
								Clients are granted a non-exclusive license to use 
								the deliverables provided by nuclecode for their intended purposes.
							</li>
						</ul>
					</section>

					<section className="bold">
						5. Confidentiality
					</section>

					<section>
						<ul>
							<li>
								nuclecode respects the confidentiality of our clients' 
								information and will not disclose any confidential 
								information without prior consent.
							</li>
							<li>
								Clients agree to keep confidential any proprietary 
								information shared by nuclecode during the course of the project.
							</li>
						</ul>
					</section>

					<section className="bold">
						6. Liability
					</section>

					<section>
						<ul>
							<li>
								nuclecode is not liable for any damages, including but not 
								limited to loss of data, revenue, or profits, arising 
								from the use of our services.
							</li>
							<li>
								We strive to deliver high-quality work, but we cannot 
								guarantee that our services will be error-free or uninterrupted.
							</li>
						</ul>
					</section>

					<section className="bold">
						7. Termination
					</section>

					<section>
						<ul>
							<li>
								Either party may terminate the agreement with written 
								notice if the other party breaches any material 
								term of the agreement and fails to remedy the 
								breach within a reasonable time.
							</li>
							<li>
								Upon termination, clients are responsible for 
								payment for services rendered up to the termination date.
							</li>
						</ul>
					</section>

					<section className="bold">
						8. Governing Law
					</section>

					<section>
						<ul>
							<li>
								These terms and conditions are governed by the 
								laws of England and Wales.
							</li>
							<li>
								Any disputes arising from these terms and conditions 
								will be subject to the exclusive jurisdiction 
								of the courts of England and Wales.
							</li>
						</ul>
					</section>

					<section>
						By using nuclecode's website or services, you acknowledge 
						that you have read, understood, and agree to these terms 
						and conditions. If you have any questions or concerns, 
						please contact us at legal@nuclecode.com.
					</section>

					<section>
						These terms and conditions were last updated on March 1, 2024.
					</section>
				</div>
			</div>
		</Layout>
	);
}

export default TermsAndConditions;